.floatContainer {
    padding: 10px;
    display: grid;
    width: 75%;
    max-width: 1500x;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
    align-items: center;
    margin: 0 auto;
}

.article, .floatSidebar{
    padding: 4rem;
    background: #fff;
    align-content: center;
}

.articleHeader{
    text-align: center;
}
.articleCategory{
    display: block;
    font-size: 14px;
    padding: 10px 0;
}
.articleTitle{
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-size: 30px;
    margin: 0;
    font-weight: 400;
}

.postedBy{
    font-size: 15px;
    font-style: italic;
    padding: 10px 0;
    display: block;
    color: #6c6c6c;
}

.articleImageContainer{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.articleImageContainer img{
    max-width:100%;
    max-height: 100%;
}

.articleContent{
    margin: 10px;
}

.articleContent p{
    font-size: 14px;
    color: "#6c6c6c";
    font-weight: 300;
    letter-spacing: 1px;
    padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .floatContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
     }
  }
