.hero-container {
    /*background: url() center center/cover no-repeat;*/
    height: 75vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.hero-container > p {
    margin-top: 250px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .hero-container > p {
    margin-left: 1px;
    margin-right: 1px;
    text-align: center;
    font-size: 30px;
  }

.hero-btn {
    margin-top: 32px;
} 

.hero-btn .btn {
    margin: 6px;
}

.btn-mobile {
    display: block;
    text-decoration: none;
}

.btn {
    width: 100%;
}